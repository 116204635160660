export interface ISizes<T> {
  medium?: T;
  transitional?: T;
  small?: T;
  big?: T;
  [key: string]: T;
}

export const sizes: ISizes<number> = {
  big: 1280,
  medium: 1024,
  transitional: 900,
  small: 640,
};

export type MediaTemplate = ISizes<(...args: any[]) => any>;

export const media: MediaTemplate =
  Object.keys(sizes).reduce((acc: MediaTemplate, label: string) => {
    acc[label] = (template: TemplateStringsArray) => `
      @media all and (max-width: ${sizes[label]}px) {
        ${template.raw.map((r) => r)}
      }
    `;
    return acc;
  }, {});
