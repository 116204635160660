import { toast } from 'react-toastify';

export const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content)
    .then(() => {
        toast.success('Conteúdo copiado para sua área de transferência.');
    })
    .catch(_ => {
        toast.error('Falha ao copiar, tente novamente!')
    });
}