import React, { DetailedHTMLProps } from 'react';

type TabPanelProps = {
  index: any;
  value: any;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TabPanel: React.FC<TabPanelProps> = ({ children, index, value, ...other}) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );  
};

export default TabPanel;
