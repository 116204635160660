import * as React from "react";
import { Charge } from "../../../../graphql/types/checkoutOrders";
import { lighten } from '@mui/system'
import {
  parseChargeStatus,
  ChargeStatusColor
} from "../../../../helpers/charges";
import styled from "styled-components";
import { gray16 } from '../../../../style/colors';

const Container = styled.div<{ color: string }>`
  margin: 0.2em 0;
  font-size: 0.8125rem;
  padding: 3px 10px;
  width: fit-content;
  border-radius: 100px;

  font-weight: 500;
  color: ${({ color }) => color};
  background-color: ${({ color }) => lighten(color, 0.88)};
`;

export const ChargeStatusBadge = ({ status }: { status: Charge["status"] }) => {
  return (
    <Container color={ChargeStatusColor[status] || gray16}>
      {parseChargeStatus(status)}
    </Container>
  );
};
