import React, { useState } from 'react';
import { Query } from 'react-apollo';

import { QUERY_CURRENT_USER } from '../../../graphql/queries/users';
import ProfileAddAddressSection from './ProfileAddAddressSection';
import ProfileAddressesSection from './ProfileAddressesSection';

const Profile = () => {
  const [showFormAddress, setShowFormAddress] = useState<boolean>(false);

  return (
      <Query key={'ProfilePersonalInfo'} query={QUERY_CURRENT_USER} fetchPolicy='cache-and-network'>
        {
          (result) => [
            <ProfileAddressesSection key='profileAddressesSection' queryResult={result} showFormAddress={showFormAddress} setShowFormAddress={setShowFormAddress} />,
            <ProfileAddAddressSection key='profileAddAddressesSection' refetch={result.refetch} showFormAddress={showFormAddress} setShowFormAddress={setShowFormAddress} />,
          ]
        }
      </Query>
  );
}

export default Profile;
