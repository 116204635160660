import React, { useEffect, useState } from "react";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { IUserAction, LOGOUT_USER } from "../../../../actionReducers/User";
import { IAppState } from "../../../../store";
import * as S from "./style";
import ArrowBtn from "../../../../assets/img/Home/arrow.svg";
import logo from "../../../../assets/img/Home/allu_new_logo.png";
import burguer from "../../../../assets/img/Home/burguer.png";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  ICartAction,
  LOAD_DATA_FROM_LOCAL_STORAGE
} from "../../../../actionReducers/Cart";
import {
  IGeneralAction,
  LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE
} from "../../../../actionReducers/General";
import SidebarMobile from "../SidebarMobile";
import CustomButton from "../../../../components/Rebranding/CustomButton";
import { Grid, Stack } from "@mui/material";
import MaterialLink from '@mui/material/Link';

type NewType = ActionCreator<IGeneralAction>;

interface IUserProps extends RouteComponentProps<{}> {
  loggedState: boolean;
  logout: ActionCreator<IUserAction>;
  redirect?: (path: string) => void;
  missingPayment?: boolean;
  loadDataFromLocalStorage: ActionCreator<ICartAction>;
  loadTypePaymentFromLocalStorage: NewType;
}

const NewNavBar = ({
  redirect,
  loggedState,
  logout,
  missingPayment,
  location,
  loadDataFromLocalStorage,
  loadTypePaymentFromLocalStorage
}: IUserProps) => {
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState<boolean>(false);

  const [fixedBar, setFixedBar] = useState<boolean>(false);
  const [isResponsive, setIsResponsive] = useState<boolean>(() => {
    if (window.screen.width <= 900) {
      return true;
    } else {
      return false;
    }
  });

  const [isPageLogin, setIsPageLogin] = useState<boolean>(false);

  useEffect(() => {
    const url: string = window.location.pathname;
    if (url === "/login") {
      setIsPageLogin(true);
    } else {
      setIsPageLogin(false);
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const referral = query.get("referral");
    if (referral) {
      sessionStorage.setItem("referral", referral);
    }

    loadDataFromLocalStorage();
    loadTypePaymentFromLocalStorage();
  }, []);

  useEffect(() => {
    const listener = () => {
      if (window.screen.width <= 900) {
        setIsResponsive(true);
      } else {
        setIsResponsive(false);
      }
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (window.pageYOffset > 10) {
      setFixedBar(true);
    }
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setFixedBar(true);
    } else {
      setFixedBar(false);
    }
  };

  const handlerLogout = () => {
    logout();
  };

  const toogleMenuMobile = () => {
    setIsOpenMenuMobile(!isOpenMenuMobile);
  };

  const notLogoutMissingPayment = () => {
    toast.error(
      "Não é possivel sair com pagamento pendente. Por favor efetue o pagamento ou clique em cancelar"
    );
  };

  const getHelpCenterLink = () => (
    <Link to={{ pathname: "https://ajuda.allugator.com" }} target="_blank" className="link_actions link_central_ajuda">
      central de ajuda
    </Link>
  )

  return (
    <>
      {!isResponsive && (
        <Grid
          container
          sx={{
            flexDirection: "row",
            bgcolor: "#2D2D38",
            height: "90px",
            alignItems: "center",
            justifyContent: "space-around",
            position: 'sticky',
            zIndex: 2,
            transition: 'background-color ease-in 0.3s, opacity ease-in 0.3s',
            top: 0
          }}
        >
          <Grid>
            <Link to={{ pathname: "https://allugator.com" }} target="_blank">
              <img src={logo} alt="allu logo" className="alluLogo" />
            </Link>
          </Grid>
          <>
            {loggedState ? (
              <Grid>
                <S.Actions
                  loggedState={loggedState}
                  missingPayment={missingPayment}
                  isFixedBar={fixedBar}
                >
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    {window.location.pathname !== "/buscar" ? (
                      <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank' className="link_actions link_produtos">produtos</MaterialLink>
                    ) : null}
                    {getHelpCenterLink()}
                    {window.location.pathname !== "/pedidos/agendados" ? (
                      <Link
                        to="/pedidos/agendados"
                        className="link_actions link_pedidos"
                      >
                        meus pedidos
                      </Link>
                    ) : null}
                    {/* {window.location.pathname !== "/perfil/editar" ? (
                      <Link
                        to={
                          missingPayment
                            ? "/finalizar-aluguel"
                            : "/perfil/editar"
                        }
                        className="link_actions link_editarConta"
                      >
                        editar conta
                      </Link>
                    ) : null} */}
                    <Link
                      to={missingPayment ? "/finalizar-aluguel" : "/"}
                      onClick={
                        !missingPayment
                          ? handlerLogout
                          : notLogoutMissingPayment
                      }
                      className="link_actions link_sair"
                    >
                      sair
                    </Link>
                  </Stack>
                </S.Actions>
              </Grid>
            ) : (
              <Grid>
                <S.Actions
                  loggedState={loggedState}
                  missingPayment={missingPayment}
                  isFixedBar={fixedBar}
                >
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    {window.location.pathname !== "/buscar" ? (
                      <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank' className="link_actions">produtos</MaterialLink>

                    ) : null}
                    {getHelpCenterLink()}
                    <CustomButton
                      title="já sou allu"
                      onClick={() => redirect("/login")}
                      style={{
                        backgroundColor: "transparent",
                        color: "#F4F4EF",
                        width: "205px",
                        height: "42px",
                        border: "2px solid #F4F4EF",
                        borderRadius: "21px",
                        padding: "0px",
                        '&:hover': {
                          transform: "scale(1.1)",
                          transition: "all .2s ease-in-out",
                        },
                      }}
                    />
                    <CustomButton
                      title="quero ser allu"
                      onClick={() => redirect("/cadastrar")}
                      endIcon={<img src={ArrowBtn} />}
                      style={{
                        backgroundColor: "#27AE60",
                        color: "#2D2D38",
                        width: "277px",
                        height: "42px",
                        borderRadius: "21px",
                        padding: "0px",
                        '&:hover': {
                          transform: "scale(1.1)",
                          transition: "all .2s ease-in-out",
                        }
                      }}
                    />
                  </Stack>
                </S.Actions>
              </Grid>
            )}
          </>
        </Grid>
      )}
      {isResponsive && (
        <>
          <S.MenuMobile loggedState={loggedState}
            missingPayment={missingPayment}
            isFixedBar={fixedBar} >
            <Grid
              container
              sx={{
                flexDirection: "row",
                bgcolor: "#2D2D38",
                height: "56px",
                alignItems: "center",
                justifyContent: "space-between",
                paddingX: "10%",
                position: 'fixed',
                zIndex: 2,
                transition: 'background-color ease-in 0.3s, opacity ease-in 0.3s',
                top: 0
              }}
            >
              <Grid>
                <Link to={{ pathname: "https://allugator.com" }} target="_blank">
                  <img src={logo} alt="allu logo" className="alluLogo" />
                </Link>
              </Grid>
              <Grid>
                <button onClick={toogleMenuMobile} className="burguerBtn">
                  <img src={burguer} alt="side bar button" />
                </button>
              </Grid>
            </Grid>
          </S.MenuMobile>
          <>
            {isOpenMenuMobile && (
              <SidebarMobile
                openMenu={isOpenMenuMobile}
                toogleMenuMobile={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                isPageLogin={isPageLogin}
                redirect={redirect}
              />
            )}
          </>
        </>
      )}
    </>
  );
};
export default connect(
  (state: IAppState) => ({
    loggedState: state.user.logged,
    missingPayment: state.general.missingPayment
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    logout: () => dispatch(LOGOUT_USER()),
    loadDataFromLocalStorage: () => dispatch(LOAD_DATA_FROM_LOCAL_STORAGE()),
    loadTypePaymentFromLocalStorage: () =>
      dispatch(LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE())
  })
)(withRouter(NewNavBar));
