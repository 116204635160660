import { Box, Stack, Typography } from '@mui/material';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { GraphQLError } from 'graphql';
import * as moment from 'moment';
import React, { useState } from 'react';
import { MutationFn, QueryResult } from 'react-apollo';
import { toast } from 'react-toastify';
import TextFieldMask from '../../../components/Inputs/InputMask';
import InputText from '../../../components/Inputs/InputText';
import InputSelect from '../../../components/InputSelect';
import PhoneNumberVerificationModal from '../../../components/PhoneNumberVerificationModal';
import CustomButton from '../../../components/Rebranding/CustomButton';
import listGenders from '../../../constants/genders';
import { IMutationEditMe } from '../../../graphql/mutations/users';
import { IQueryCurrentUser } from '../../../graphql/queries/users';
import { IPublicUser } from '../../../graphql/types/users';
import { validatorEditPersonalDataSchema } from './../../../validators/validatorEditPersonalData';
import { IGeneralAction, SET_LOADING } from '../../../actionReducers/General';
import { IAppState } from '../../../store';
import { ActionCreator, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { gray11 } from '../../../style/colors';

interface IProfilePersonalFields {
  firstName: string;
  lastName: string;
  phone: string;
  birthday: string;
  document: string;
  gender?: string;
  email: string;
}

interface IProfilePersonalSectionProps {
  submit: MutationFn<IMutationEditMe>;
  queryResult: QueryResult<IQueryCurrentUser>;
  setLoading: ActionCreator<IGeneralAction>;
  loading: boolean;
  refetch: any;
}

const initialValues: IProfilePersonalFields = {
  firstName: '',
  lastName: '',
  phone: '',
  birthday: '',
  document: '',
  gender: '',
  email: '',
};

type Props = IProfilePersonalSectionProps & FormikProps<IProfilePersonalFields>;

const ProfilePersonalSection = ({ errors, values, touched, loading, handleBlur, handleChange, handleSubmit, refetch, queryResult }: Props) => {
  const { data } = queryResult;
  const [phoneNumberVerificationModalOpen, setPhoneNumberVerificationModalOpen] = useState<boolean>();

  const togglePhoneNumberVerificationModal = async (state: boolean) => {
    setPhoneNumberVerificationModalOpen(state);
    await refetch();
  }

  const renderPhoneNumberVerificationWarning = (user: IPublicUser) => {
    const isPhoneNumberFieldDirty = user && user.phone && user.phone.replace(/\D+/g, '') !== values.phone;

    if (!isPhoneNumberFieldDirty && !user.flags.hasVerifiedPhoneNumber && !errors.phone) {
      return (
        <Typography fontFamily={'Chillax'} color='red' fontSize={14} sx={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => togglePhoneNumberVerificationModal(true)}>
          Número de celular não verificado,&nbsp;
          <strong>verifique-o aqui.</strong>
        </Typography>
      );
    }

    return null;
  }

  return (
    <Box>
      <Stack spacing={'24px'} sx={{ paddingTop: '48px' }}>
        <Typography fontFamily={'Chillax'} fontWeight={500} fontSize={21} color={gray11}>Meus dados</Typography>
        <InputText
          label="Nome"
          type="text"
          onBlur={handleBlur('firstName')}
          setValue={handleChange('firstName')}
          value={values.firstName || ''}
          touched={touched.firstName}
          errorMessage={errors.firstName}
          isRequired={true}
          name={"firstName"}
        />
        <InputText
          label="Sobrenome"
          type="text"
          onBlur={handleBlur('lastName')}
          setValue={handleChange('lastName')}
          value={values.lastName || ''}
          touched={touched.lastName}
          errorMessage={errors.lastName}
          isRequired={true}
          name={"lastName"}
        />
        <InputSelect
          value={values.gender || ''}
          setValue={handleChange('gender')}
          label={'Gênero'}
          options={listGenders}
          touched={touched.gender}
          errorMessage={errors.gender}
          required={true}
          name={"gender"}
        />
        <InputText
          label="Email"
          type="text"
          onBlur={handleBlur('email')}
          setValue={handleChange('email')}
          value={values.email || ''}
          touched={touched.email}
          errorMessage={errors.email}
          isRequired={true}
          name={"email"}
          disabled={true}
        />
        <Stack>
          <TextFieldMask
            mask="(99)99999-9999"
            label="Telefone"
            type="text"
            onBlur={handleBlur('phone')}
            setValue={handleChange('phone')}
            value={values.phone || ''}
            touched={touched.phone}
            errorMessage={errors.phone}
            isRequired={true}
            name={"phone"}
          />
          {data && data.currentUser ? renderPhoneNumberVerificationWarning(data.currentUser) : null}
        </Stack>

        <TextFieldMask
          mask="999.999.999-99"
          label="CPF"
          type="text"
          onBlur={handleBlur('document')}
          setValue={handleChange('document')}
          value={values.document || ''}
          touched={touched.document}
          errorMessage={errors.document}
          isRequired={true}
          name={"document"}
          disabled={true}
        />
        <InputText
          label="Data de Nascimento"
          type="date"
          name={"birthday"}
          onBlur={handleBlur('birthday')}
          setValue={handleChange('birthday')}
          value={values.birthday}
          touched={touched.birthday}
          errorMessage={errors.birthday}
          shrink={true}
        />
      </Stack>
      <Box sx={{ marginTop: '40px' }}>
        <CustomButton
          variant="contained"
          fullWidth
          size="large"
          color="primary"
          style={{ width: '100%' }}
          loading={loading}
          onClick={() => handleSubmit()}
          title={'salvar dados'}
        />
      </Box>
      <PhoneNumberVerificationModal
        display={phoneNumberVerificationModalOpen}
        phoneNumber={data.currentUser.phone}
        closeModal={() => togglePhoneNumberVerificationModal(false)}
        userCurrentId={data.currentUser.id}
      />
    </Box>
  );
}

export default connect((state: IAppState) => ({
  loading: state.general.loading,
}), (dispatch: Dispatch<IGeneralAction>) => ({
  setLoading: (loadingState: boolean) => dispatch(SET_LOADING(loadingState)),
}))(
  withFormik<IProfilePersonalSectionProps, IProfilePersonalFields>({
    validationSchema: validatorEditPersonalDataSchema,
    mapPropsToValues(props) {
      const { queryResult } = props;

      if (queryResult.data) {
        const { currentUser } = queryResult.data;

        if (currentUser) {
          return {
            firstName: currentUser.personalData.firstName || initialValues.firstName,
            lastName: currentUser.personalData.lastName || initialValues.lastName,
            phone: currentUser.phone || initialValues.phone,
            birthday: currentUser && currentUser.personalData.birthday
              ? moment(currentUser.personalData.birthday)
                .add(3, "hour")
                .format("YYYY-MM-DD") : '',
            document: currentUser.personalData.document || '',
            gender: currentUser.personalData && currentUser.personalData.gender || '',
            email: currentUser && currentUser.email || ''
          };
        }
      }

      return initialValues;
    },
    enableReinitialize: true,
    async handleSubmit(
      values: IProfilePersonalFields,
      { props }: FormikBag<IProfilePersonalSectionProps, IProfilePersonalFields>,
    ) {
      const { submit, setLoading, refetch } = props;

      try {
        setLoading(true);
        const phone = values.phone.replace(/\D+/g, '');
        const birthday = moment(moment(values.birthday, 'YYYY-MM-DD')).toDate();
        await submit({ variables: { ...values, phone, birthday } });

        toast.success('Dados alterados com sucesso.');
        setLoading(false);
        await refetch();
      } catch (error) {
        setLoading(false);
        error.graphQLErrors.map((e: GraphQLError) => toast.error(e.message));
      }
    },
  })(ProfilePersonalSection));
