import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ActionCreator, Dispatch } from "redux";
import { IUserAction, LOGOUT_USER } from "../../../../actionReducers/User";
import { Icon } from "../../../../components/UI/Icon";
import { IAppState } from "../../../../store";
import {
  ContainerCloseIcon,
  LogoMobile,
  SideBarContainer,
  SidebarMobileContainer
} from "./style";
import logo from "../../../../assets/img/Home/allu_new_logo.png";
import { Link } from "react-router-dom";
import * as S from "./../NavBar/style";
import ArrowBtn from "../../../../assets/img/Home/arrow.svg";
import CustomButton from "../../../../components/Rebranding/CustomButton";

import MaterialLink from '@mui/material/Link';

interface IPropsSidebarMobile {
  loggedState: boolean;
  darkMode?: boolean;
  missingPayment?: boolean;
  history?: History;
  openMenu: boolean;
  logout: ActionCreator<IUserAction>;
  toogleMenuMobile(): void;
  redirect?: (path: string) => void;
  isPageLogin?: boolean;
}

const SidebarMobile: React.FC<IPropsSidebarMobile> = ({
  openMenu,
  toogleMenuMobile,
  loggedState,
  logout,
  missingPayment,
  redirect,
  isPageLogin
}: IPropsSidebarMobile) => {
  const [userLogged, setUserLogged] = useState<boolean>(false);

  useEffect(() => {
    setUserLogged(loggedState);
  }, []);

  const handlerLogout = () => {
    if (missingPayment) {
      notLogoutMissingPayment();
    } else {
      logout();
      setUserLogged(false);
    }
  };

  const notLogoutMissingPayment = () => {
    toast.error(
      "Não é possivel sair com pagamento pendente. Por favor efetue o pagamento ou clique em cancelar"
    );
  };

  const getHelpCenterLink = ({ classNames }: { classNames?: string }) => (
    <Link to={{ pathname: "https://ajuda.allugator.com" }} target="_blank" className={"link_actions_mobile " + classNames}>
      central de ajuda
    </Link>
  )

  return (
    <SideBarContainer>
      <SidebarMobileContainer isOpen={openMenu}>
        <LogoMobile src={logo} alt="logo_allugator_mobile" />
        <span className="mobileMenu">
          {userLogged ? (
            <S.Actions
              loggedState={loggedState}
              missingPayment={missingPayment}
              isFixedBar={true}
            >
              {window.location.pathname !== "/buscar" ? (
                <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank' className="link_actions_mobile">Produtos</MaterialLink>) : null}
              {getHelpCenterLink({})}
              {window.location.pathname !== "/pedidos/agendados" ? (
                <Link to="/pedidos/agendados" className="link_actions_mobile">
                  meus pedidos
                </Link>
              ) : null}
              {/* {window.location.pathname !== "/perfil/editar" &&
                window.location.pathname !== "/perfil" ? (
                <Link
                  to={missingPayment ? "/finalizar-aluguel" : "/perfil/editar"}
                  className="link_actions_mobile"
                >
                  editar conta
                </Link>
              ) : null} */}
              <Link
                to={missingPayment ? "/finalizar-aluguel" : "/"}
                onClick={
                  !missingPayment ? handlerLogout : notLogoutMissingPayment
                }
                className="link_actions link_logout"
              >
                sair
              </Link>
            </S.Actions>
          ) : (
            <>
              {!isPageLogin ? (
                <div className="mobile_container">
                  <S.Actions loggedState={!loggedState} isFixedBar={true}>
                    {window.location.pathname !== "/buscar" ? (
                      <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank' className="link_actions_mobile">produtos</MaterialLink>) : null}
                    {getHelpCenterLink({ classNames: "link_central_ajuda" })}
                    <CustomButton
                      title="já sou allu"
                      onClick={() => redirect("/login")}
                      style={{
                        backgroundColor: "#2D2D38",
                        color: "#F4F4EF",
                        width: "277px",
                        height: "42px",
                        border: "2px solid #F4F4EF",
                        borderRadius: "21px",
                        padding: "0px",
                        marginBottom: "25px"
                      }}
                    />
                    {/* <button onClick={() => redirect('/login')} className="login-btn" >
                      já sou allu
                    </button> */}
                    {/* <button
                      onClick={() => redirect("/cadastrar")}
                      className="cadastro-btn"
                    >
                      quero ser allu
                      <img src={ArrowBtn} alt="sign up arrow" />
                    </button> */}
                    <CustomButton
                      title="quero ser allu"
                      onClick={() => redirect("/cadastrar")}
                      endIcon={<img src={ArrowBtn} />}
                      style={{
                        backgroundColor: "#27AE60",
                        color: "#2D2D38",
                        width: "277px",
                        height: "42px",
                        borderRadius: "21px",
                        padding: "0px"
                      }}
                    />
                  </S.Actions>
                </div>
              ) : null}
            </>
          )}
        </span>
        <ContainerCloseIcon onClick={toogleMenuMobile}>
          <Icon style={{ fontSize: "2.3rem", color: "#FFF" }}>close</Icon>
        </ContainerCloseIcon>
      </SidebarMobileContainer>
    </SideBarContainer>
  );
};

export default connect(
  (state: IAppState) => ({
    loggedState: state.user.logged,
    missingPayment: state.general.missingPayment
  }),
  (dispatch: Dispatch<IUserAction>) => ({
    logout: () => dispatch(LOGOUT_USER())
  })
)(SidebarMobile);
