import moment from 'moment';
import { Charge, ChargeCategories, ChargeStatus, Transaction, TransactionStatus } from '../graphql/types/checkoutOrders'
import { blue, gray16, green4, orange1, red } from '../style/colors';

export const ChargeCategoriesColor: { [key in ChargeCategories]: string } = {
  [ChargeCategories.ALLU_CARE]: blue,
  [ChargeCategories.ALLU_SHIELD]: blue,
  [ChargeCategories.FINE_CANCELLATION]: red,
  [ChargeCategories.PARCEL]: gray16,
  [ChargeCategories.SETUP]: gray16,
};

export const ChargeStatusColor: { [key in ChargeStatus]: string } = {
  [ChargeStatus.PAID]: green4,
  [ChargeStatus.UNPAID]: gray16,
  [ChargeStatus.PENDING]: orange1,
  [ChargeStatus.FAILED]: red,
  [ChargeStatus.REFUNDED]: red,
  [ChargeStatus.CANCELED]: red,
  [ChargeStatus.CHARGEDBACK]: red,
  [ChargeStatus.OVERPAID]: gray16,
  [ChargeStatus.UNDERPAID]: gray16,
  [ChargeStatus.PROCESSING]: blue,
  [ChargeStatus.PARTIAL_REFUNDED]: gray16
};

export const TransactionStatusColor: { [key in TransactionStatus]: string } = {
  [TransactionStatus.NOT_AUTHORIZED]: red,
  [TransactionStatus.AUTHORIZED_PENDING_CAPTURE]: blue,
  [TransactionStatus.CAPTURED]: green4,
  [TransactionStatus.PARTIAL_CAPTURE]: green4,
  [TransactionStatus.WAITING_CAPTURE]: blue,
  [TransactionStatus.REFUNDED]: blue,
  [TransactionStatus.VOIDED]: red,
  [TransactionStatus.PARTIAL_REFUNDED]: blue,
  [TransactionStatus.PARTIAL_VOID]: red,
  [TransactionStatus.ERROR_ON_VOIDING]: red,
  [TransactionStatus.ERROR_ON_REFUNDING]: red,
  [TransactionStatus.WAITING_CANCELLATION]: blue,
  [TransactionStatus.WITH_ERROR]: red,
  [TransactionStatus.FAILED]: red,
};

export const parseChargeStatus = (status: Charge['status']) => {
  const parser = {
    [ChargeStatus.PAID] : 'Pago' ,
    [ChargeStatus.UNPAID] : 'A vencer',
    [ChargeStatus.PENDING] : 'Pendente',
    [ChargeStatus.FAILED] : 'Não pago',
    [ChargeStatus.REFUNDED] : 'Estornado',
    [ChargeStatus.CANCELED] : 'Cancelado',
    [ChargeStatus.CHARGEDBACK] : 'Chargedback',
    [ChargeStatus.OVERPAID] : 'Pago em Excesso',
    [ChargeStatus.UNDERPAID] : 'Pagamento Insuficiente',
  }

  return parser[status] || status
}

export const parseChargeCategories = (status: Charge['category']) => {
  const parser = {
    [ChargeCategories.ALLU_CARE] : 'Allu Care' ,
    [ChargeCategories.ALLU_SHIELD] : 'Allu Shield',
    [ChargeCategories.FINE_CANCELLATION] : 'Taxa de Cancelamento',
    [ChargeCategories.PARCEL] : 'Parcela',
    [ChargeCategories.SETUP] : 'Setup',
  }

  return parser[status] || status
}

export const parseTransactionStatus = (method: Transaction['status']) => {
  const parser = {
    [TransactionStatus.NOT_AUTHORIZED]: 'Não autorizado',
    [TransactionStatus.AUTHORIZED_PENDING_CAPTURE]: 'Autorizado a Aprovar',
    [TransactionStatus.CAPTURED]: 'Aprovado',
    [TransactionStatus.PARTIAL_CAPTURE]: 'Aprovação Parcial',
    [TransactionStatus.WAITING_CAPTURE]: 'Aguardando Aprovação',
    [TransactionStatus.REFUNDED]: 'Reembolsado',
    [TransactionStatus.VOIDED]: 'Anulado',
    [TransactionStatus.PARTIAL_REFUNDED]: 'Reembolso Parcial',
    [TransactionStatus.PARTIAL_VOID]: 'Anulação Parcial',
    [TransactionStatus.ERROR_ON_VOIDING]: 'Erro ao Anular',
    [TransactionStatus.ERROR_ON_REFUNDING]: 'Erro ao Reembolsar',
    [TransactionStatus.WAITING_CANCELLATION]: 'Aguardando Cancelamento',
    [TransactionStatus.WITH_ERROR]: 'Com erro',
    [TransactionStatus.FAILED]: 'Falhou',
  } 

  return parser[method] || method
}

export const orderTransactions = (
  transactions: Transaction[]
): Transaction[] => {
  return transactions.sort((leftTransaction, rightTransaction) =>
    moment(leftTransaction.createdAt).isAfter(
      moment(rightTransaction.createdAt)
    )
      ? -1
      : 1
  ) || [];
};