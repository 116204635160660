import * as React from 'react';
import { Mutation, withApollo, WithApolloClient } from 'react-apollo';
import { MUTATION_CHANGE_MY_PASSWORD, MUTATION_EDIT_ME } from '../../../graphql/mutations/users';
import { IQueryCurrentUser, QUERY_CURRENT_USER } from '../../../graphql/queries/users';
import ProfilePasswordSection from './ProfilePasswordSection';
import ProfilePersonalSection from './ProfilePersonalSection';

const Profile: React.FC<WithApolloClient<{}>> = ({ client }) => {
  const [queryResult, setQueryResult] = React.useState(null);
  const queryCurrentUser = client.watchQuery<IQueryCurrentUser>({
    query: QUERY_CURRENT_USER,
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    (async () => {
      const result = await queryCurrentUser.result();
      setQueryResult(result);
    })();
  }, []);

  const refetchCurrentUser = async () => {
    const result = await queryCurrentUser.refetch();
    setQueryResult(result);
  };

  return (
    <>
      {queryResult && (
        <Mutation mutation={MUTATION_EDIT_ME}>
          {(editMe) => <ProfilePersonalSection queryResult={queryResult} refetch={refetchCurrentUser} submit={editMe} />}
        </Mutation>
      )}

      <Mutation key={'ProfilePasswordSection'} mutation={MUTATION_CHANGE_MY_PASSWORD}>
        {(changeMyPassword) => <ProfilePasswordSection submit={changeMyPassword} />}
      </Mutation>
    </>
  );
};

export default withApollo(Profile);
