import styled from 'styled-components';
import { blue2, gray1, gray15, gray4, gray8, green1, greenAllu } from '../../../../style/colors';
import { media } from '../../../../helpers/style';
import { lighten } from '@mui/system'


export const Content = styled.div`
  display: grid;
  min-height: 80vh;
  align-content: flex-start;
`

export const GoBackButton = styled.button`
  cursor: pointer;
  transition: all 0.2s;

  width: fit-content;
  padding: 8px 32px;
  margin: 0 0 20px;
  border-radius: 21px;
  border: 1px solid ${greenAllu};
  background-color: transparent;

  font-size: 1.3125rem;
  font-weight: 500;

  :hover {
    background-color: ${green1};
    scale: 0.95;
    color: white;
  }
`

export const NormalButton = styled.button`
  cursor: pointer;
  transition: all 0.2s;

  border: none;
  width: fit-content;
  padding: 8px 22px;
  border-radius: 4px;
  background-color: ${greenAllu};
  box-shadow: 0px 1px 5px 0px #0000001F;
  box-shadow: 0px 2px 2px 0px #00000024;
  box-shadow: 0px 3px 1px -2px #00000033;


  color: white;
  font-size: 0.9375rem;
  font-weight: 500;

  :hover {
    background-color: ${green1};
    scale: 0.95;
    color: white;
  }

  ${media.transitional`
    width: 100%;
  `}
`

export const ActionButton = styled.button`
  transition: all 0.2s;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  color: ${greenAllu};
  border-radius: 4px;
  border: 1.5px solid ${lighten(greenAllu, 0.5)};
  background-color: transparent;
  padding: 8px;
  height: fit-content;
  
  cursor: pointer;
  width: auto;

  :hover {
    background-color: ${greenAllu};
    scale: 0.95;
    color: white;
  }
`

export const ClipboardButton = styled.button`
  transition: all 0.2s;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  color: ${lighten(blue2, 0.13)};
  border-radius: 4px;
  border: 1.5px solid ${blue2};
  background-color: transparent;
  padding: 8px;
  height: fit-content;
  
  cursor: pointer;
  width: auto;

  :hover {
    background-color: ${blue2};
    scale: 0.95;
    color: white;
  }
  
  ${media.transitional`
    width: 100%;
  `}
`

export const PaginationButton = styled.button<{ disabled: boolean }>`
  transition: all 0.2s;
  border: none;
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  color: ${({ disabled }) => disabled ? gray4 : gray8};

  :hover {
    scale: ${({ disabled }) => disabled ? '1' : '0.95'};
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  }
`

export const Title = styled.text`
  margin: 0 0 20px;
  font-size: 1.75rem;
  font-weight: 600;
`

export const Info = styled.text<{ small?: boolean, bold?: boolean, color?: string }>`
  margin: 0.2em 0;
  font-size: 1rem;
  font-weight: 400;
  
  ${({ small }) => small && `
    font-size: 0.75rem;
  `}
  
  ${({ bold }) => bold && `
    font-weight: bold;
  `}
  
  ${({ color }) => color && `
    color: ${color};
  `}

  strong {
    font-weight: 500 !important;
  }
`

export const OrderInfo = styled.div`
  img {
    margin: 0 10px 0 0;
    height: auto;
    width: 65px;
    object-fit: contain;
  }
  
  ${media.transitional`
    img {
      display:none;
    }
  `}
`

type ChargeItemTypes = {
  isOpen?: boolean;
  expandable?: boolean;
  bgColor?: string
};

export const ExpandableItemContainer = styled.div<ChargeItemTypes>`
  width: 100%;
  overflow: hidden;
  border: none;
  margin: 0 0 2px;
  background-color: ${(props: ChargeItemTypes) => (props.bgColor || 'transparent')};
  cursor: ${(props: ChargeItemTypes) => (props.expandable ? 'pointer' : 'default')};

  ${media.transitional`
    border: 1px solid #0000003B;
    border-radius: 8px;
    margin: 0 0 16px;
  `}

`;

export const ExpandableItemHeader = styled.div<ChargeItemTypes>`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isOpen }) => isOpen && `
    background-color: ${gray15};
  `}
`;

export const ExpandableItemContent = styled.div<ChargeItemTypes>`
  transition: all 0.5s ease-in;
  overflow: hidden;
  background-color: ${gray1};

  display: ${(props: ChargeItemTypes) => (props.isOpen ? 'block' : 'none')};
  animation: ${(props: ChargeItemTypes) =>
    props.isOpen ? 'openAnimation 0.2s ease-in' : 'none'};
  @keyframes openAnimation {
    from {
      max-height: 0;
    }
    to {
      max-height: 100%;
    }
  }
`;

export const Chevron = styled.div<ChargeItemTypes>`
  margin-left: auto;
  transform: ${(props: ChargeItemTypes) =>
    props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
`;