import React, { useEffect, useState } from 'react';
import { AssignmentOutlined } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Divider, Grid, Link, Stack, Typography } from "@mui/material"
import { IProduct } from '../../../graphql/types/products';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { getProductShortName } from '../../../helpers/getProductShortName';
import getStorage from '../../../helpers/getStorageProduct';
import toReal from '../../../helpers/toReal';
import { IPayment } from '../../../actionReducers/Recurrence';
import { validatePaymentRecurrenceStepCheckout } from '../../../helpers/validateDataUser';
import { ICheckoutOrderProduct, ISubscriptionPayment } from '../../../graphql/types/checkoutOrders';
import Config from '../../../config';

interface IProps {
    orderProduct: ICheckoutOrderProduct[];
    loading: boolean;
    acceptedTerms: boolean;
    singleCard: boolean;
    paymentEntry: IPayment;
    paymentMonthly: IPayment;
    subscriptionPayment: ISubscriptionPayment;
    protocolLink: string;
    loyaltyPlan: string;
    setAcceptedTerms: (value: boolean) => void;
    submit: () => void;
}

const ResumeCheckout = ({
    orderProduct,
    loading,
    singleCard,
    acceptedTerms,
    paymentEntry,
    paymentMonthly,
    subscriptionPayment: { entryFeeValue },
    protocolLink,
    loyaltyPlan,
    submit,
    setAcceptedTerms
}: IProps) => {

    const [product, setProduct] = useState<IProduct>();

    useEffect(() => {
        if (orderProduct && orderProduct.length) {
            setProduct(orderProduct[0].product);
        }
    }, [orderProduct])

    return (
        <Grid
            item
            padding={'24px'}
            sx={{
                boxShadow: '0px 5px 8px rgba(120, 111, 111, 0.15)',
                borderRadius: '8px',
            }}
        >
            <Box display={'flex'} gap={'10px'}>
                <AssignmentOutlined color={'action'} />
                <Typography variant="body1" fontSize={'17px'}>
                    RESUMO DO PEDIDO
                </Typography>
            </Box>
            <Box marginTop={'24px'} display={'flex'} justifyContent={'space-between'}>
                {product && product.photos && product.photos.length && (
                    <img
                        src={product.photos[0]}
                        width={'86'}
                        height={'116'}
                        alt={'Produto'}
                    />
                )}
                <Box display={'flex'} flexDirection={'column'} alignItems={'end'}>
                    <Typography variant={'subtitle1'} color={'#333333'} fontSize={'17px'}>
                        Produto
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        {product && product.name && getProductShortName(product.name)}
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        {product && product.name && getStorage(product.name).label}
                    </Typography>
                </Box>
            </Box>
            <Divider variant="fullWidth" color="#E4E4E4" sx={{ marginY: '20px' }} />
            <Stack spacing={'8px'}>
                {
                    entryFeeValue ? (
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                                Entrada
                            </Typography>
                            <Typography variant={'subtitle1'} color={'#666666'} fontSize={'15px'}>
                                {toReal(paymentEntry.amount)}
                            </Typography>
                        </Box>
                    ) : null
                }
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Valor
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        {toReal(paymentMonthly.amount)}/mês
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Tipo de assinatura
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        Recorrência
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Plano de fidelidade
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        {loyaltyPlan} meses
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Período de pagamento
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        Recorrência
                    </Typography>
                </Box>
            </Stack>
            <Divider variant="fullWidth" color="#E4E4E4" sx={{ marginY: '20px' }} />
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Checkbox
                    defaultChecked
                    disabled={!validatePaymentRecurrenceStepCheckout(paymentEntry, paymentMonthly, singleCard)}
                    value={acceptedTerms}
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                    style={{ paddingTop: 0 }}
                />
                <Typography variant="caption" fontSize={13}>
                    Eu li e aceito os{' '}
                    <Link
                        href={Config.termosDeUsoUrl}
                        target="_blank"
                    >
                        Termos de Uso
                    </Link>{' '}
                    e tenho ciência de que estou assinando um{' '}
                    <Link
                        href={protocolLink}
                        target="_blank"
                    >
                        Contrato de Serviço
                    </Link>{' '}
                    com a allu.
                </Typography>
            </Box>
            <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                disabled={!validatePaymentRecurrenceStepCheckout(paymentEntry, paymentMonthly, singleCard) || !acceptedTerms || loading ? true : false}
                onClick={() => submit()}
                style={{ marginTop: 15 }}
            >
                {!loading ? 'Finalizar' : <CircularProgress size={'20px'} />}
            </Button>
        </Grid>
    )
};

export default connect((state: IAppState) => ({
    loading: state.general.loading,
    singleCard: state.checkoutRecurrence.singleCard,
    paymentEntry: state.checkoutRecurrence.paymentEntry,
    paymentMonthly: state.checkoutRecurrence.paymentRecurrence,
    orderProduct: state.checkoutRecurrence.orderProduct,
    subscriptionPayment: state.checkoutRecurrence.order.subscriptionPayment,
    protocolLink: state.checkoutRecurrence.order.protocolLink,
    loyaltyPlan: state.checkoutRecurrence.order.loyaltyPlan,
}))(ResumeCheckout);