import { Box, Stack, Typography } from '@mui/material';
import { FormikBag, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ActionCreator, Dispatch } from 'redux';
import { IGeneralAction, SET_LOADING } from '../../../actionReducers/General';
import InputText from '../../../components/Inputs/InputText';
import CustomButton from '../../../components/Rebranding/CustomButton';
import { IMutationChangeMyPassword } from '../../../graphql/mutations/users';
import { IAppState } from '../../../store';
import { validatorChangePasswordSchema } from '../../../validators/validatorChangePassword';
import { gray11 } from '../../../style/colors';
interface IProfilePasswordSectionValues {
  oldPassword: string;
  newPassword: string;
}

interface IProfilePasswordSectionProps {
  submit: MutationFn<IMutationChangeMyPassword>;
  setLoading: ActionCreator<IGeneralAction>;
  loading: boolean;
}

const initialValues: IProfilePasswordSectionValues = {
  oldPassword: '',
  newPassword: '',
};

type Props = IProfilePasswordSectionProps & FormikProps<IProfilePasswordSectionValues>;

const ProfilePasswordSection = ({ handleSubmit, handleChange, handleBlur, values, errors, touched, loading }: Props) => {
  return (
    <Box sx={{ paddingBottom: '72px' }}>
      <Stack spacing={'24px'} sx={{ paddingTop: '48px' }}>
        <Typography fontFamily={'Chillax'} fontWeight={500} fontSize={21} color={gray11}>Senha</Typography>
        <InputText
          label="Senha antiga"
          type="password"
          onBlur={handleBlur('oldPassword')}
          setValue={handleChange('oldPassword')}
          value={values.oldPassword || ''}
          touched={touched.oldPassword}
          errorMessage={errors.oldPassword}
          isRequired={true}
          name={"oldPassword"}
        />
        <InputText
          label="Nova senha"
          type="password"
          onBlur={handleBlur('newPassword')}
          setValue={handleChange('newPassword')}
          value={values.newPassword || ''}
          touched={touched.newPassword}
          errorMessage={errors.newPassword}
          isRequired={true}
          name={"newPassword"}
        />
      </Stack>
      <Box sx={{ marginTop: '40px' }}>
        <CustomButton
          variant="contained"
          fullWidth
          size="large"
          color="primary"
          style={{ width: '100%' }}
          loading={loading}
          onClick={() => handleSubmit()}
          title={'alterar senha'}
        />
      </Box>
    </Box>
  );
}

export default connect((state: IAppState) => ({
  loading: state.general.loading,
}), (dispatch: Dispatch<IGeneralAction>) => ({
  setLoading: (loadingState: boolean) => dispatch(SET_LOADING(loadingState)),
}))(withFormik<IProfilePasswordSectionProps, IProfilePasswordSectionValues>({
  validationSchema: validatorChangePasswordSchema,
  mapPropsToValues() {
    return initialValues;
  },
  async handleSubmit(
    values: IProfilePasswordSectionValues,
    bag: FormikBag<IProfilePasswordSectionProps, IProfilePasswordSectionValues>,
  ) {
    const { resetForm } = bag;
    const { submit, setLoading } = bag.props;
    setLoading(true);
    try {
      const result = await submit({ variables: values });

      setLoading(false);
      if (result && result.data.changeMyPassword) {
        toast.success('Senha alterada com sucesso.');
      } else {
        toast.error('Erro ao alterar senha.');
        return;
      }
      resetForm();
    } catch (e) {
      setLoading(false);
      toast.error('Erro ao alterar senha.');
    }
  },
})(ProfilePasswordSection));
