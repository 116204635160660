import * as React from "react";
import { Charge } from '../../../../graphql/types/checkoutOrders';
import { Info, PaginationButton } from './styled';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';

type PaginationProps = {
  data: Charge[]
  setData: (charges: Charge[]) => void
}

export const Pagination = ({ data, setData }: PaginationProps) => {
  const [pagination, setPagination] = React.useState({ page: 1, size: 10, previous: false, next: data.length > 10 })
  const [label, setLabel] = React.useState({ from: 1, to: data.length < 10 ? data.length : 10 })

  const handlePagination = (option: 'next' | 'previous') => {

    const page = pagination.page + (option === 'next' ? 1 : -1)
    const total = data.length;
    const previous = page > 1;
    const next = (page * pagination.size) < total;
    setPagination({...pagination, page, previous, next})

    const from = (page - 1) * pagination.size;
    const to = page * pagination.size
    setLabel({ from: from === 0 ? 1 : from, to: to > data.length ? data.length : to })
  }

  React.useEffect(() => {
    const start = (pagination.page - 1) * pagination.size;
    const end = start + pagination.size;
    setData(data.slice(start, end))
  }, [pagination, data])

  React.useEffect(() => {
    window.scrollTo({ top: 200, behavior: 'smooth' });
  }, [pagination])

  return (
    <Grid container justifyContent={'flex-end'} alignItems={'center'} style={{ marginTop: '20px', marginBottom: '50px' }}>
      <Info>{label.from + '-' + label.to + ' de ' + data.length}</Info>
      <PaginationButton disabled={!pagination.previous} onClick={() => handlePagination('previous')}>
        <KeyboardArrowLeftOutlined />
      </PaginationButton>
      <PaginationButton disabled={!pagination.next} onClick={() => handlePagination('next')}>
        <KeyboardArrowRightOutlined />
      </PaginationButton>
    </Grid>
  )
}