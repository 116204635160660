import { Action } from 'redux';

import { ALLUGATOR_AUTH_TOKEN, CAMPAIGN, MEDIUM, MELIUZ, SOURCE } from '../boot/constants';
import Cookies from '../helpers/Cookies';
import { UserActionTypes } from './actionTypes';

export interface IUserAction extends Action<UserActionTypes> {
  [extraProp: string]: any;
}

/**
 * Reducer
 */

export interface IUserState {
  logged: boolean;
  hasNormalOrder: boolean;
}

const initialState: IUserState = {
  logged: !!Cookies.get(ALLUGATOR_AUTH_TOKEN),
  hasNormalOrder: false,
};

export const userReducer = (state: IUserState = initialState, action: IUserAction): IUserState => {
  switch (action.type) {
    case UserActionTypes.SET_LOGGED_STATE: {
      return { ...state, logged: action.loggedState };
    }

    case UserActionTypes.SET_HAS_NORMAL_ORDER: {
      return { ...state, hasNormalOrder: action.hasNormalOrder };
    }

    default:
      return state;
  }
};

/**
 * Actions
 */

export const SET_LOGGED_STATE = (loggedState: boolean) => ({
  type: UserActionTypes.SET_LOGGED_STATE,
  loggedState,
});

export const SET_HAS_NORMAL_ORDER = (hasNormalOrder: boolean) => ({
  type: UserActionTypes.SET_HAS_NORMAL_ORDER,
  hasNormalOrder,
});

export const LOG_USER = (token: string): IUserAction => {
  Cookies.set(ALLUGATOR_AUTH_TOKEN, token, { path: '/' });
  return SET_LOGGED_STATE(true);
};

export const LOGOUT_USER = (): IUserAction => {
  Cookies.remove(ALLUGATOR_AUTH_TOKEN, { path: '/' });
  return SET_LOGGED_STATE(false);
};

export const MELIUZ_COOKIE = (token: string): any => {
  Cookies.set(MELIUZ, token, { path: '/', maxAge: 604800 });
};

export const SOURCE_COOKIE = (token: string): any => {
  Cookies.set(SOURCE, token, { path: '/', maxAge: 604800 });
};

export const MEDIUM_COOKIE = (token: string): any => {
  Cookies.set(MEDIUM, token, { path: '/', maxAge: 604800 });
};

export const CAMPAIGN_COOKIE = (token: string): any => {
  Cookies.set(CAMPAIGN, token, { path: '/', maxAge: 2592000 });
};
