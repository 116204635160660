import { ApolloQueryResult } from 'apollo-client';
import { History } from 'history';
import * as moment from 'moment-timezone';
import * as React from 'react';

import { BlackBox, BlackBoxClose } from '../../../components/styled';
import { IQueryMyOrders } from '../../../graphql/queries/checkoutOrders';
import { ICheckoutOrder, ICheckoutOrderProduct } from '../../../graphql/types/checkoutOrders';
import toReal from '../../../helpers/toReal';
import OrderDetail from './OrderDetail';
import {
  ActionButton,
  CardInfo,
  OrderProductCardContainer,
  OrderProductCardContent,
  OrderProductCardInfo,
  OrderProductCardFooter,
  RedirectButton,
} from './styled';
import { uppercaseFirstLetter } from '../../../helpers/uppercasefirstLetter';
import { orderTransactions } from '../../../helpers/charges';
import { IPublicUser } from '../../../graphql/types/users';

export enum ListType {
  NEXT = 'next',
  ACTIVE = 'active',
  FINISHED = 'finished',
}

interface IProps {
  next?: boolean;
  type: ListType;
  order: ICheckoutOrder;
  history?: History;
  currentUser: IPublicUser;
  orderOpenModal?: String
  clearQuery?:() => void;

  refetchOrders?: () => Promise<ApolloQueryResult<IQueryMyOrders>>;
}

interface IState {
  modalOpen: boolean;
  orderIndex: number;
}

function nextBilling(day: number): string {
  const today = moment();
  const currentDay = today.date();
  const currentMonth = today.month();
  const currentYear = today.year();

  let targetDate;

  if (currentDay < day) {
    targetDate = moment({ year: currentYear, month: currentMonth, day });
  } else {
    targetDate = moment({ year: currentYear, month: currentMonth + 1, day });
  }

  return targetDate.format('DD/MM/YYYY');
}

class OrderProducts extends React.Component<IProps> {
  public state: IState = {
    modalOpen: false,
    orderIndex: 0,
  };

  public renderOrderProduct =
    (
      type: ListType,
      { checkoutOrder, product, amountPaid }: ICheckoutOrderProduct,
      order: ICheckoutOrder,
      index: number,
    ) => {
      const subscription = order.subscription
      
      const handleInfo = () => { this.setState({ orderIndex: index }); this.openModal(); }
      const card = () => {
        const lastCharge = order.charges?.filter(charge => charge.transactions.length > 0).pop()
        const transactions = orderTransactions(lastCharge?.transactions || [])
        const lastTransaction = transactions.pop()
        
        const brand = lastTransaction?.cardBrand || '-'
        const lastDigits = lastTransaction?.cardLastDigits || '-'
        if (lastTransaction) {
          return <CardInfo small><strong>Cartão: </strong>{uppercaseFirstLetter(brand)} final <strong>{lastDigits}</strong></CardInfo>
        }
      }

      const handleCharges = () => {
        this.props.history.push(`/pedidos/${order.shortId}/faturas`);
      }

      return (
        <OrderProductCardContainer key={`${index}::${checkoutOrder}::${product}`} type={type}>
          <OrderProductCardContent>
            <img src={product.photos[0]} />
            <OrderProductCardInfo>
              <CardInfo small border><strong>{order.checkoutOrderProducts[0].state}</strong></CardInfo>
              <CardInfo><strong>Pedido: </strong>{order.shortId} <RedirectButton onClick={handleInfo}>Ver histórico de envio</RedirectButton></CardInfo>
              <CardInfo>{product.name}</CardInfo>
              {subscription && (
                <>
                  <br />
                  <CardInfo><strong>Plano de fidelidade: </strong>{subscription?.cycleQuantity} meses</CardInfo>
                  <br />
                  <CardInfo><strong>Valor: </strong>{toReal(subscription?.cycleValue/100)}/mês</CardInfo>
                  <CardInfo small><strong>Próximo pagamento: </strong>{nextBilling(subscription.billingDay)}</CardInfo>
                  {card()}
                </>
              )}
              {!subscription && (
                <>
                  <CardInfo><strong>Valor: </strong>{toReal(amountPaid ? amountPaid : this.props.order.amount)}</CardInfo>
                </>
              )}
            </OrderProductCardInfo>
          </OrderProductCardContent>

          <OrderProductCardFooter>
            {subscription && (
              <ActionButton onClick={handleCharges}>Ver faturas</ActionButton>
            )}
          </OrderProductCardFooter>
        </OrderProductCardContainer>
      );
    }

  public renderModal = () => {
    const { order, refetchOrders, next, orderOpenModal } = this.props;
    const { modalOpen, orderIndex } = this.state;

    if ((modalOpen || order.shortId === orderOpenModal) && order.checkoutOrderProducts[orderIndex]) {
      return [
        <OrderDetail
          refetchOrders={refetchOrders}
          key={`modal::${order._id}`}
          closeFn={this.closeModal}
          order={order}
          next={next}
          orderProduct={order.checkoutOrderProducts[orderIndex]}
          history={this.props.history}
          currentUser={this.props.currentUser}
        />,
        <BlackBox key={`blackBoxOpen::${order._id}`} onClick={this.closeModal} />,
      ];
    }

    return <BlackBoxClose key={`blackBoxClose::${order._id}`} />;
  }

  public openModal = () => {
    window.scrollTo(0, 0);
    this.setState({ modalOpen: true });
  }

  public closeModal = () => {
    const { clearQuery } = this.props;

    clearQuery();
    
    this.setState({ modalOpen: false });
  };

  public render() {
    const { order, type } = this.props;
    return [
      ...order.checkoutOrderProducts
        .sort((a, b) => new Date(a.start).getTime() < new Date(b.end).getTime() ? 1 : -1)
        .map((cop, index) => this.renderOrderProduct(type, cop, order, index)),
      this.renderModal()
    ];
  }
}

export default OrderProducts;
