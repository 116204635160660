import { Pix } from "@mui/icons-material";
import { ApolloQueryResult } from "apollo-client";
import { History } from "history";
import * as moment from "moment-timezone";
import * as React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';
import { Link, Typography } from "@mui/material";
import Icon from "../../../components/UI/Icon";
import { IQueryMyOrders } from "../../../graphql/queries/checkoutOrders";
import {
  CheckoutOrderStatus,
  ICheckoutOrder,
  ICheckoutOrderProduct
} from "../../../graphql/types/checkoutOrders";
import toReal from "../../../helpers/toReal";
import { CloseButton } from "../components/styled";
import AddShipping from "./AddShipping";
import ChangeCard from "./ChangeCard";
import EditShipping from "./EditShipping";
import {
  ActionButton,
  AddDocumentsButton,
  DeliveryButton,
  DeliveryInformation,
  Information,
  MoreInfoModal,
  OrderData,
  PaymentInformation,
  UseTerms,
  ViewQRCodePixButton
} from "./styled";
import ViewQRCodePix from "./ViewQRCodePix";
import Config from '../../../config';
import { PaymentType } from "../../../actionReducers/Checkout";
import { IPublicUser } from "../../../graphql/types/users";

interface IProps {
  order: ICheckoutOrder;
  orderProduct: ICheckoutOrderProduct;
  next: boolean;
  history: History;
  currentUser: IPublicUser;

  closeFn: () => void;
  refetchOrders: () => Promise<ApolloQueryResult<IQueryMyOrders>>;
}

enum OrderPage {
  DEFAULT,
  ADDSHIPPING,
  EDITSHIPPING,
  CHANGECARD,
  QRCODEPIX
}

interface IState {
  page: OrderPage;
}

class OrderDetail extends React.Component<IProps, IState> {
  public state: IState = {
    page: OrderPage.DEFAULT
  };


  public goToPage = (page: OrderPage) => {
    const self = this;
    return () => self.setState({ page });
  };

  public verifyShowButtonBillet = (state: string, order: any) => {
    if (
      state === "Aguardando Pagamento" &&
      order.dataPayment &&
      order.dataPayment.payment_method === "boleto"
    ) {
      return true;
    }
    return false;
  };

  public verifyShowButtonDocumentation = (state: string) => {
    if (
      state === "Solicitação de Documentos" ||
      state === "Nova Solicitação de Documentos"
    ) {
      return true;
    }
    return false;
  };

  public verifyCurrentState = (state: string, currentState: string) => {
    if (
      state === currentState
    ) {
      return true;
    }
    return false;
  }

  public formatCardNumber = (data: string) => {
    if (data) return `************${data.split("X").pop()}`;
    return "Cartão não informado";
  };

  public render() {
    const {
      order,
      orderProduct,
      closeFn,
      refetchOrders,
      next,
      history
    } = this.props;
    const { page } = this.state;
    const {
      _id,
      shortId,
      coupon,
      createdAt,
      deliveryAddress,
      contract,
      recurrence,
    } = order;

    const { state, stateDates, trackCode } = orderProduct;

    switch (page) {
      case OrderPage.DEFAULT: {
        return (
          <MoreInfoModal key={`modal::${_id}`}>
            <CloseButton onClick={closeFn}>
              <Icon>close</Icon>
            </CloseButton>

            <Information>Pedido #{shortId}</Information>

            <OrderData>
              <p><span className='highlight'>Produto:</span> {orderProduct.product.name} </p>
              <p><span className='highlight'>Realizado em:</span> {moment.tz(createdAt, "UTC").format("DD/MM/YYYY")}</p>
              {
                order.subscription && (
                  <>
                    <p><span className='highlight'>Plano de fidelidade:</span> {order.subscription.cycleQuantity} meses </p>
                    <p><span className='highlight'>Plano de fidelidade termina em:</span> {moment.tz(orderProduct.end, "UTC").format("DD/MM/YYYY")}</p>
                  </>
                )
              }
              {
                coupon && (
                  <p><span>Cupom utilizado:</span> {coupon.slug}&nbsp; <span>({coupon.amount}%)</span></p>
                )
              }
            </OrderData>

            {contract && (
              <Information>
                <ActionButton
                  style={{
                    width: '174px',
                    height: '32px',
                    fontSize: '14px',
                    marginTop: '16px',
                    borderRadius: '21px',
                    padding: '1px 2px',
                    lineHeight: 1,
                  }}
                  onClick={() => window.open(contract, '_blank')}>Ver contrato do pedido</ActionButton>
              </Information>
            )}
            {deliveryAddress ? (
              <>
                <DeliveryInformation>
                  <strong>Endereço de Entrega</strong>
                  <p
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    {deliveryAddress.street}, {deliveryAddress.number}
                    {deliveryAddress.complement
                      ? ` - ${deliveryAddress.complement}`
                      : ""}
                    , &nbsp;{deliveryAddress.neighborhood}, {deliveryAddress.city}
                    . (cep: {deliveryAddress.code})
                  </p>
                </DeliveryInformation>
                {
                  this.props.currentUser.addresses
                  && this.props.currentUser.addresses
                  && this.props.currentUser.addresses.length
                  && (
                    <DeliveryInformation>
                      <strong>Endereço Residencial:</strong>
                      <p
                        style={{
                          fontSize: '14px'
                        }}
                      >
                        {this.props.currentUser.addresses[0]?.street}, {this.props.currentUser.addresses[0]?.number}
                        {this.props.currentUser.addresses[0]?.complement
                          && ` - ${this.props.currentUser.addresses[0]?.complement}`
                        }
                        , &nbsp;{this.props.currentUser.addresses[0]?.neighborhood}, {this.props.currentUser.addresses[0]?.city}
                        . (cep: {this.props.currentUser.addresses[0]?.code})
                      </p>
                    </DeliveryInformation>
                  )
                }
              </>
            ) : (
              <DeliveryInformation>
                <strong>Você deve retirar os produtos em:</strong>
                <p
                  style={{
                    fontSize: '14px',
                  }}
                >Rua Santa Fé, 100, Sala 309.</p>

                {next ? (
                  <DeliveryButton
                    onClick={this.goToPage(OrderPage.ADDSHIPPING)}
                    style={{ padding: '8px', width: 'fit-content' }}
                  >
                    Adicionar frete ao pedido
                  </DeliveryButton>
                ) : null}
              </DeliveryInformation>
            )}

            {recurrence ? (
              <>
                <Information
                  style={{
                    marginTop: 12,
                    marginBottom: '14px',
                    paddingTop: '16px',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  }}
                >
                  Informação de pagamento:
                </Information>

                <PaymentInformation>
                  <Icon>credit_card</Icon>
                  <div>
                    {order.subscriptionPayment?.lastDigitsCard
                      ? `************${order.subscriptionPayment.lastDigitsCard}`
                      : this.formatCardNumber(order.lastCard)}
                  </div>
                </PaymentInformation>
              </>
            ) : null}

            {order.qrCodePix &&
              order.qrCodePix !== "" &&
              order.status === CheckoutOrderStatus.GENERATED_PIX ? (
              <>
                <Information style={{ marginTop: 12 }}>
                  Informação de pagamento:
                </Information>

                <PaymentInformation>
                  <Pix
                    sx={{
                      width: "22px",
                      height: "16px",
                      fill: true ? "#10D878" : "rgba(0, 0, 0, 0.38)"
                    }}
                  />
                  <div>
                    PIX
                    <ViewQRCodePixButton
                      onClick={this.goToPage(OrderPage.QRCODEPIX)}
                    >
                      Visualizar QR Code
                    </ViewQRCodePixButton>
                  </div>
                </PaymentInformation>
              </>
            ) : null}

            {order.dataPayment?.payment_method === PaymentType.PICPAY && order.dataPayment?.link ? (
              <>
                <Information style={{ marginTop: 12 }}>
                  Informação de pagamento:
                </Information>

                <PaymentInformation>
                  <div>
                    PICPAY
                    <ViewQRCodePixButton
                      onClick={() => window.open(order.dataPayment.link, '_blank').focus()}
                    >
                      Abrir link de pagamento
                    </ViewQRCodePixButton>
                  </div>
                </PaymentInformation>
              </>
            ) : null}

            <OrderData
              style={{
                paddingTop: '16px',
                fontSize: '16px',
                paddingBottom: '14px',
              }}
            >
              <p>
                <span className='highlight'>Total:</span> {
                  toReal(
                    orderProduct.amountPaid
                      ? orderProduct.amountPaid
                      : order.amount
                  )
                }
              </p>
            </OrderData>

            <Information
              style={{
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                paddingTop: '16px',
              }}
            >
              Status
            </Information>

            <Timeline sx={{
              paddingX: 0,
              alignSelf: 'flex-start'
            }}>
              <TimelineChild stateDate={stateDates && stateDates?.aguardandoPagamento || stateDates && stateDates?.pagamentoAprovado}
                stateName="Aguardando Pagamento" />
              <TimelineChild stateDate={stateDates && stateDates?.pagamentoAprovado} stateName="Pagamento Aprovado" />
              <TimelineChild stateDate={stateDates && stateDates?.solicitacaoDeDocumentos} stateName="Solicitação de Documentos" stateButton={
                next && this.verifyShowButtonDocumentation(state) ? (
                  <div>
                    <AddDocumentsButton
                      onClick={() => {
                        history.push("/documents");
                      }}
                    >
                      Enviar Documentação
                    </AddDocumentsButton>
                  </div>
                ) : null} />
              <TimelineChild stateDate={stateDates && stateDates?.documentacaoEmAnalise} stateName="Documentação em Análise" />
              <TimelineChild stateDate={stateDates && stateDates?.documentacaoAprovada} stateName="Documentação Aprovada" stateButton={
                this.verifyCurrentState(state, "Documentação Aprovada") ? (
                  <div>
                    <Typography style={
                      {
                        fontSize: 14,
                        color: 'rgba(0, 0, 0, 0.6)'
                      }
                    }>
                      O seu pedido está sendo carinhosamente preparado para envio! O prazo de entrega começa a contar a partir de hoje ({moment(stateDates?.documentacaoAprovada).format('DD/MM/YYYY')}). Neste prazo está incluso a liberação do produto para a allu para que ele possa ir até você sem nenhum problema. Quando o aparelho for enviado, você receberá o código de rastreio.
                    </Typography>
                  </div>
                ) : null} />
              <TimelineChild stateDate={stateDates && stateDates?.pedidoEnviado} stateName="Pedido Enviado" stateButton={
                this.verifyCurrentState(state, "Pedido Enviado") && trackCode ? (
                  <Typography sx={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.6)', display: 'inline' }}>
                      Código de rastreio: {trackCode}
                  </Typography>
                ) : null} />
              <TimelineChild stateDate={stateDates && stateDates?.pedidoEntregue} stateName="Pedido Entregue" lastChild />
            </Timeline>

            <UseTerms>
              <Link
                href={Config.termosDeUsoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos de uso
              </Link>
            </UseTerms>
            <UseTerms>
              <Link
                href={Config.avisoProtecaoDadosUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Aviso de proteção de dados
              </Link>
            </UseTerms>
          </MoreInfoModal>
        );
      }

      case OrderPage.ADDSHIPPING: {
        return (
          <MoreInfoModal key={`modal::${_id}`}>
            <CloseButton left={true} onClick={this.goToPage(OrderPage.DEFAULT)}>
              <Icon>keyboard_arrow_left</Icon>
            </CloseButton>

            <AddShipping
              refetchOrders={refetchOrders}
              orderId={_id}
              goBack={this.goToPage(OrderPage.DEFAULT)}
            />
          </MoreInfoModal>
        );
      }

      case OrderPage.EDITSHIPPING: {
        return (
          <MoreInfoModal key={`modal::${_id}`}>
            <CloseButton left={true} onClick={this.goToPage(OrderPage.DEFAULT)}>
              <Icon>keyboard_arrow_left</Icon>
            </CloseButton>

            <EditShipping
              refetchOrders={refetchOrders}
              orderId={_id}
              goBack={this.goToPage(OrderPage.DEFAULT)}
            />
          </MoreInfoModal>
        );
      }

      case OrderPage.CHANGECARD: {
        return (
          <MoreInfoModal key={`modal::${_id}`}>
            <CloseButton left={true} onClick={this.goToPage(OrderPage.DEFAULT)}>
              <Icon>keyboard_arrow_left</Icon>
            </CloseButton>

            <ChangeCard
              refetchOrders={refetchOrders}
              orderId={_id}
              goBack={this.goToPage(OrderPage.DEFAULT)}
            />
          </MoreInfoModal>
        );
      }

      case OrderPage.QRCODEPIX: {
        return (
          <MoreInfoModal key={`modal::${_id}`}>
            <CloseButton left={true} onClick={this.goToPage(OrderPage.DEFAULT)}>
              <Icon>keyboard_arrow_left</Icon>
            </CloseButton>
            <ViewQRCodePix
              qrCode={order.qrCodePix}
              amount={
                orderProduct.amountPaid ? orderProduct.amountPaid : order.amount
              }
              createdAt={order.createdAt}
            />
          </MoreInfoModal>
        );
      }

      default: {
        return null;
      }
    }
  }
}

interface TimelineChildProps {
  stateDate: Date;
  stateName: string;
  lastChild?: boolean;
  stateButton?: React.ReactElement;
}

const TimelineChild = ({ stateDate, stateName, lastChild, stateButton }: TimelineChildProps): React.ReactElement => {
  const color = stateDate ? "success" : "grey"
  const enabledStatusColor = stateDate ? "#2e7d32" : "#bdbdbd" // These colors are the hexadecimal values from success and grey color tokens.

  return (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary"
        sx={{
          paddingX: 0,
          textAlign: 'left',
          maxWidth: {
            xs: '100px',
            md: '300px'
          },
          minWidth: {
            xs: '100px',
            md: '175px'
          }
        }}
      >
        {
          stateDate
            ? new Date(stateDate).toLocaleDateString('pt-BR', { dateStyle: 'short' })
            : ''
        }
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={color} />
        {!lastChild && <TimelineConnector sx={{
          backgroundColor: enabledStatusColor,
        }}
        />}
      </TimelineSeparator>
      <TimelineContent sx={{display: 'flex', flexDirection: 'column', minWidth: {md: '400px'}}} color={enabledStatusColor}>{stateName} {stateButton}</TimelineContent>
    </TimelineItem>
  )
}

export default OrderDetail;
