import * as yup from 'yup';

export const validatorPersonalDataRecurrence = yup.object().shape({
    email: yup
        .string()
        .email('Formato de e-mail inválido')
        .nullable(true)
        .required('Necessário informar um e-mail'),
    document: yup.string().nullable(true).required('Necessário informar número de CPF').min(11, 'Número de CPF inválido'),
});
