import { GeosuggestProps } from 'react-geosuggest';
import styled from 'styled-components';

import GeosuggestInput from '../../../components/GeosuggestInput';
import { CommonDiv, CommonInput, CommonMaskedInput, RawButton, RawLink } from '../../../components/styled';
import { media, sizes } from '../../../helpers/style';
import { gray2, gray3, gray5, red, white } from '../../../style/colors';
import { gradientAddressBox, gradientDarkGreen } from '../../../style/gradients';

export const ProfileWrapper = styled(CommonDiv)`
margin-top: 20px;
  flex-direction: column;
  padding-bottom: 10em;
`;

/** Header */
export const ProfileHeaderWrapper = styled(CommonDiv)`
  background: ${gradientDarkGreen};
  padding-top: 23em;
  margin-bottom: 10em;
`;

export const ProfileHeaderSizer = styled(CommonDiv)`
  flex-direction: column;
  max-width: ${sizes.big}px;
`;

export const ProfileHeaderTitle = styled.h1`
  flex-grow: 1;
  font-size: 6em;
  font-weight: 700;
  color: ${white};
  margin: 0;
  text-align: center;
`;

export const ProfileHeaderNavigation = styled(CommonDiv)`
  margin-top: 5em;
`;

interface IProfileHeaderNavigationLinkProps {
  selected?: boolean;
}

export const ProfileHeaderNavigationLink = styled(RawLink)`
  position: relative;
  font-size: 2.4em;
  font-weight: 700;
  color: ${(props: IProfileHeaderNavigationLinkProps) =>
    !props.selected
      ? 'rgba(255, 255, 255, 0.6)'
      : white
  };
  background: none;
  padding: 1.2em 2.5em 1.5em;

  &:hover {
    color: ${(props: IProfileHeaderNavigationLinkProps) =>
    !props.selected
      ? 'rgba(255, 255, 255, 0.8)'
      : white
  };
  }

  flex-direction: column;
  align-items: center;
`;

export const ProfileHeaderNavigationActiveDetail = styled(CommonDiv)`
  position: absolute;
  background-color: ${white};
  border-radius: 2px;
  width: 18px;
  height: 18px;
  left: calc(50% - 9px);
  transform: rotate(-45deg);
  bottom: -15px;
`;

/** Personal Info Section */
export const ProfilePersonalInfoWrapper = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  padding: 9.5em 5em 5em;
  width: 80%;
  max-width: calc(480px - 10em);
  border-left: 2px solid ${gray5};

  ${media.small`
      padding: 2em 2em 5em;
      border: unset;
      width: calc(100% - 4em);
    `
  }
`;

export const ProfileSectionIcon = styled(CommonDiv)`
  position: absolute;
  top: 0;
  left: -19px;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background-color: ${gray5};
  color: ${white};

  ${media.small`
      position: relative;
      top: unset;
      left: unset;
    `
  }
`;

export const ProfileSectionEndIcon = styled(ProfileSectionIcon)`
  position: absolute;
  bottom: 0;
  top: unset;
  left: -7px;
  width: 2em;
  height: 2em;
  box-shadow: 0px 0px 0px 2px ${gray5};
  background-color: ${white};

  ${media.small`
      display: none;
    `
  }
`;

export const ProfileSectionEndDot = styled(CommonDiv)`
  width: 1em;
  height: 1em;
  background-color: ${gray5};
  border-radius: 50%;

  ${media.small`
      display: none;
    `
  }
`;

/** Profile Password Section */
export const ProfilePasswordWrapper = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  padding: 10em 5em;
  width: 80%;
  max-width: calc(480px - 10em);
  border-left: 2px solid ${gray5};

  ${media.small`
      border: none;
      width: calc(100% - 4em);
      padding: 5em 2em;
    `
  }
`;

interface IProfileInputProps {
  notValid?: boolean;
}

const profileInputStyle = `
  font-size: 18px;
  padding: 1em 1.1em !important;
  ${(props: IProfileInputProps) => props.notValid && 'box-shadow: 0px 0px 0px 2px red;'}
  ${(props: IProfileInputProps) => props.notValid && '&:hover { box-shadow: 0px 0px 0px 2px red; }'}

  &:focus {
    box-shadow: 0px 0px 0px 2px #80bdff;
    ${(props: IProfileInputProps) => props.notValid && 'box-shadow: 0px 0px 0px 2px red;'}
  }
`;

export const ProfileInput = styled(CommonInput)`
  ${profileInputStyle}

  &::placeholder {
    color: gray;
  }
`;

export const ProfileGeosuggestInput = styled<GeosuggestProps>(GeosuggestInput)`
  .geosuggest__input {
    ${profileInputStyle}
    ${media.small`
      border: 1px solid ${gray3} !important;
    `}
  }
`;

export const ProfileInputSkeleton = styled.div`
  width: calc(100% - 2.2em);
  background: ${gray2};
  height: 56px;
  border-radius: 6px;
`;

export const ProfileMaskedInput = styled(CommonMaskedInput)`
  ${profileInputStyle}

  &::placeholder {
    color: gray;
  }
`;

/** Profile add address section */
export const ProfileAddAddressSectionWrapper = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  padding: 10em 5em;
  width: 80%;
  max-width: calc(480px - 10em);
  border-left: 2px solid ${gray5};

  ${media.small`
      padding: 2em 2em;
      border: unset;
      width: calc(100% - 4em);
    `
  }
`;

/** Profile addresses section */
export const ProfileAddressesSectionWrapper = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  padding: 9em 5em 5em;
  width: 80%;
  max-width: calc(480px - 10em);
  border-left: 2px solid ${gray5};

  ${media.small`
      width: calc(100% - 4em);
      padding: 2em 2em 5em;
      border: unset;
    `
  }
`;

export const ProfileAddressBox = styled(CommonDiv)`
  display: 'flex';
  background-color: #F8F9FA;
  border-radius: 4px;
`;

export const ProfileAddressOptions = styled(CommonDiv)`
  width: fit-content;
  position: absolute;
  top: 3em;
  right: 3em;
`;

export const ProfileAddressOptionsItem = styled(RawButton)`
  padding: 0;
  color: ${white};
  transition: opacity 0.2s;
  opacity: 0.6;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const ProfileAddressTitle = styled(CommonDiv)`
  font-weight: 700;
  font-size: 3em;
  word-break: break-all;
`;

export const ProfileAddressInfo = styled(CommonDiv)`
  font-size: 2.6em;
  opacity: 0.8;
`;

export const VerifyPhoneNumberWarning = styled.button`
  color: ${red};
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;

  & strong {
    text-decoration: underline;
  }
`;

export const ProfileSavedCouponsWrapper = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  padding: 9.5em 5em 5em;
  width: 80%;
  max-width: calc(480px - 10em);
  border-left: 2px solid ${gray5};

  ${media.small`
      padding: 2em 2em 5em;
      border: unset;
      width: calc(100% - 4em);
    `
  }
`;

export const ProfileSavedCouponBox = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  background: ${gradientAddressBox};
  padding: 5em;
  margin-top: 3em;
  width: calc(100% - 10em);
  border-radius: 4em;

  div {
    justify-content: flex-start;
    color: ${white};
    line-height: 1.5em;
  }
`;

export const ProfileSavedCouponSkeletonBox = styled(ProfileSavedCouponBox)`
  background: ${gray5};
  height: 50px;
`;

export const ProfileSavedCouponTitle = styled(CommonDiv)`
  font-weight: 700;
  font-size: 3em;
`;

export const ProfileSavedCouponInfo = styled(CommonDiv)`
  font-size: 2.6em;
  opacity: 0.8;
`;
