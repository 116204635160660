import React from 'react';
import { Stack, Typography } from '@mui/material';
import formatCPF from '../../../../helpers/formatDocument';
import { IFormikPersonalDataRecurrenceFields } from '..';

const SummaryUser = ({
  document,
  email,
}: IFormikPersonalDataRecurrenceFields) => {
  return (
    <Stack>
        <Stack direction={'row'}>
          <Typography variant="caption" fontWeight={'bold'}>
            CPF:&nbsp;
          </Typography>
          <Typography variant="caption" fontSize={13}>
            {formatCPF(document)}
          </Typography>
        </Stack>
        <Stack direction={'row'}>
          <Typography variant="caption" fontWeight={'bold'}>
            Email:&nbsp;
          </Typography>
          <Typography variant="caption" fontSize={13}>
            {email}
          </Typography>
        </Stack>
    </Stack>
  );
};

export default SummaryUser;
