import React, { useEffect, useState } from "react";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { IUserAction, LOGOUT_USER } from "../../../../actionReducers/User";
import { IAppState } from "../../../../store";
import * as S from './style';
import ArrowBtn from '../../../../assets/img/Home/arrow.svg';
import logo from '../../../../assets/img/Home/logo.svg';
import burguer from '../../../../assets/img/Home/burguer.png';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ICartAction, LOAD_DATA_FROM_LOCAL_STORAGE } from "../../../../actionReducers/Cart";
import { IGeneralAction, LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE } from "../../../../actionReducers/General";
import MaterialLink from '@mui/material/Link';
import SidebarMobile from "../SidebarMobile";

type NewType = ActionCreator<IGeneralAction>;

interface IUserProps extends RouteComponentProps<{}> {
  loggedState: boolean;
  logout: ActionCreator<IUserAction>;
  redirect?: (path: string) => void;
  missingPayment?: boolean;
  loadDataFromLocalStorage: ActionCreator<ICartAction>;
  loadTypePaymentFromLocalStorage: NewType;
}

const NewNavBar = ({ redirect, loggedState, logout, missingPayment, location,
  loadDataFromLocalStorage, loadTypePaymentFromLocalStorage }: IUserProps) => {
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState<boolean>(false);

  const [fixedBar, setFixedBar] = useState<boolean>(false);
  const [isResponsive, setIsResponsive] = useState<boolean>(() => {
    if (window.screen.width <= 900) {
      return true;
    } else {
      return false;
    }
  });

  const [isPageLogin, setIsPageLogin] = useState<boolean>(false);

  useEffect(() => {
    const url: string = window.location.pathname;
    if (url === '/login') {
      setIsPageLogin(true);
    }
    else {
      setIsPageLogin(false);
    }
  }, []);

  useEffect(() => {
    const url: string = window.location.pathname;
    const query = new URLSearchParams(location.search);
    const referral = query.get('referral');
    if (referral) {
      sessionStorage.setItem('referral', referral);
    }
    if (url !== '/obrigado') {
      loadDataFromLocalStorage();
      loadTypePaymentFromLocalStorage();
    }
  }, []);

  useEffect(() => {
    const listener = () => {
      if (window.screen.width <= 900) {
        setIsResponsive(true);
      } else {
        setIsResponsive(false);
      }
    }
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (window.pageYOffset > 10) {
      setFixedBar(true);
    }
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setFixedBar(true);
    } else {
      setFixedBar(false);
    }
  };

  const handlerLogout = () => {
    logout();
  };

  const toogleMenuMobile = () => {
    setIsOpenMenuMobile(!isOpenMenuMobile);

  };

  const notLogoutMissingPayment = () => {
    toast.error(
      "Não é possivel sair com pagamento pendente. Por favor efetue o pagamento ou clique em cancelar"
    );
  };

  return (
    <>
      <S.NavContent>
        <Link to={'/'}>
          <img className='alluLogo' src={logo} alt='allu logo' />
        </Link>
        {!isResponsive && (
          <>
            {loggedState ? (
              <S.Actions loggedState={loggedState} missingPayment={missingPayment} isFixedBar={fixedBar}>
                {
                  window.location.pathname !== '/buscar' ? <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank' className="link_actions">produtos</MaterialLink>

                    : null
                }
                {
                  window.location.pathname !== '/pedidos/agendados' ? <Link to="/pedidos/agendados" className="link_actions_pedidos">
                    meus pedidos
                  </Link>
                    : null
                }
                {/* <Link
                  to={missingPayment ? "/finalizar-aluguel" : "/perfil/editar"}
                  className="link_actions"
                >
                  editar conta
                </Link> */}
                <Link
                  to={missingPayment ? "/finalizar-aluguel" : "/"}
                  onClick={
                    !missingPayment ? handlerLogout : notLogoutMissingPayment
                  }
                  className="link_actions link_logout"
                >
                  sair
                </Link>
              </S.Actions>
            ) : null} {loggedState === false ? (
              <>
                {!isPageLogin ? (
                  <S.Actions loggedState={!loggedState} isFixedBar={fixedBar}>
                    {
                      window.location.pathname !== '/buscar' ? <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank' className="link_actions">produtos</MaterialLink> : null
                    }
                    <button onClick={() => redirect('/login')} className="login-btn">
                      já sou allu
                    </button>
                    <button onClick={() => redirect('/cadastrar')} className="cadastro-btn">
                      quero ser allu
                      <img src={ArrowBtn} alt="sign up arrow" />
                    </button>
                  </S.Actions>

                ) : null}
              </>
            ) : null}
          </>
        )}
        {!isPageLogin && (<button onClick={toogleMenuMobile} className="burguerBtn">
          <img src={burguer} alt='side bar button' />
        </button>)}
      </S.NavContent>
      {isResponsive && (
        <>
          {isOpenMenuMobile && (
            <SidebarMobile openMenu={isOpenMenuMobile} toogleMenuMobile={() => setIsOpenMenuMobile(!isOpenMenuMobile)} isPageLogin={isPageLogin} redirect={redirect} />
          )}
        </>
      )}
    </>
  )
}
export default connect(
  (state: IAppState) => ({
    loggedState: state.user.logged,
    missingPayment: state.general.missingPayment,
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    logout: () => dispatch(LOGOUT_USER()),
    loadDataFromLocalStorage: () => dispatch(LOAD_DATA_FROM_LOCAL_STORAGE()),
    loadTypePaymentFromLocalStorage: () => dispatch(LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE()),
  })
)(withRouter(NewNavBar));