import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { SET_ORDER_CONFIRMATION } from '../../../actionReducers/General';
import { IUserAction, LOG_USER } from '../../../actionReducers/User';
import { BlackBox, BlackBoxClose, CommonDiv } from '../../../components/styled';
import { State } from '../../../graphql/types/checkoutOrders';
import MainLayout from '../../../layouts/Main';
import { IAppState } from '../../../store';
import ThankYouModal from '../components/thankYou';
import OrdersList from './OrdersList';
import {
  Container, Divider, OrdersHeader,
  OrdersWrapper
} from './styled';
import { ListType } from './OrderProducts';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import ProfileAddresses from '../../Profile/Address';
import ProfileAccount from '../../Profile/Account';
import ProfileCards from '../../Profile/Cards';

enum TabsEditPersonalData {
  SUBSCRIPTIONS,
  PERSONAL_DATA,
  ADDRESS_DATA,
  CARDS_DATA,
}
import TabPanel from '../components/TabPanel';
import { WithApolloClient, withApollo } from 'react-apollo';
import { QUERY_CURRENT_USER } from '../../../graphql/queries/users';
import { IPublicUser } from '../../../graphql/types/users';
interface IState {
  modalOpen: boolean;
  orderOpenModal?: string;
  currentUser?: IPublicUser;
  tab?: TabsEditPersonalData
}

interface IProps {
  orderConfirmation: boolean;
  loggedState: boolean;
  hasNormalOrder: boolean;
  setOrderConfirmation: (state: boolean) => void;
  logUser: ActionCreator<IUserAction>;
}


class Orders extends React.Component<WithApolloClient<IProps> & RouteComponentProps<{}>, IState> {
  // private intervalToGetStatusIntegration;
  public state: IState = {
    modalOpen: false,
    tab: TabsEditPersonalData.SUBSCRIPTIONS
  };

  public componentDidMount() {
    this.findUser();
    const { loggedState, history } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const orderOpenModal = query.get('pedido');
    const token = query.get('token');
    if (orderOpenModal) this.setState({ orderOpenModal });

    if (!loggedState && token) this.props.logUser(token);
    else if (!loggedState && !token) history.push('/login?go=pedidos');
  }

  public componentDidUpdate() {
    const { orderConfirmation, history } = this.props;

    if (orderConfirmation && !this.state.modalOpen && location.pathname === '/agradecimento') {
      return (
        this.setState({ modalOpen: true })
      );
    }

    if (this.state.modalOpen === false && location.pathname !== '/pedidos/agendados') {
      history.push('/pedidos/agendados');
    }
  }
  public findUser = async () => {
    const { data } = await this.props.client.query({
      query: QUERY_CURRENT_USER,
    });

    this.setState({ currentUser: data.currentUser });
  }

  public closeModal = () => {
    const { setOrderConfirmation, history } = this.props;

    this.setState({ modalOpen: false });
    history.push('/pedidos/agendados');
    setOrderConfirmation(false);
  }

  public clearQuery = () => {
    const { history } = this.props;
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('pedido');
    queryParams.delete('token');
    history.replace({
      search: queryParams.toString()
    });

    this.setState({ orderOpenModal: '' });
  }

  lists = [
    {
      old: false,
      title: 'Pedidos em andamento:', 
      type: ListType.NEXT,
      state: [
        State.solicitacaoDeDocumentos,
        State.novaSolicitacaoDeDocumentos,
        State.documentacaoEmAnalise,
        State.documentacaoAprovada,
        State.pedidoEnviado
      ].join(','),
    },
    {
      old: false,
      title: 'Assinaturas ativas:',
      type: ListType.ACTIVE,
      state: [
        State.pedidoEntregue,
        State.devolucaoAtrasada,
        State.renovacaoPendente,
        State.renovacaoParcelada,
        State.devolucaoPendente,
        State.emRecuperacao
      ].join(','),
    },
    {
      old: true,
      title: 'Assinaturas Finalizadas:',
      type: ListType.FINISHED,
      state: [
        State.produtoDevolvido,
        State.produtoVendido,
        State.assinaturaRenovada,
        State.recuperado,
        State.naoRecuperado
      ].join(','),
    },
  ]

  public render() {
    return (
      <>
        <CommonDiv>
          {this.state.modalOpen ? (
            <BlackBox onClick={this.closeModal} />
          ) : (
            <BlackBoxClose />
          )}
          {this.state.modalOpen ? (
            <ThankYouModal close={this.closeModal} />
          ) : null}
        </CommonDiv>

        <MainLayout hideSearch={true} internal={true}>
          <Container style={{ marginBottom: '88px' }}>
            <Stack spacing={'28px'} marginTop={'28px'}>
              <Typography
                fontFamily={'Chillax'}
                color={'#000000'}
                fontWeight={700}
                sx={{
                  fontSize: '37px',
                  marginTop: {
                    xs: '48px',
                    md: '28px'
                  }
                }}
              >
                Olá, {this.state.currentUser?.personalData?.firstName.trim() || ''}
              </Typography>
              <Tabs
                value={this.state.tab}
                onChange={(_, value) => this.setState({ tab: value })}
                centered
                variant={window.screen.width <= 1250 ? 'scrollable' : 'fullWidth'}
                aria-label="simple tabs example"
              >
                <Tab label="MINHAS ASSINATURAS" id='simple-tab-0' aria-controls='simple-tabpanel-0' style={{ fontFamily: 'Roboto' }} />
                <Tab label="MEUS DADOS" id='simple-tab-1' aria-controls='simple-tabpanel-1' style={{ fontFamily: 'Roboto' }} />
                <Tab label="ENDEREÇOS DE ENTREGA" id='simple-tab-2' aria-controls='simple-tabpanel-2' style={{ fontFamily: 'Roboto' }} />
                {
                  this.props.hasNormalOrder
                  && <Tab label="MEUS CARTÕES" id='simple-tab-3' aria-controls='simple-tabpanel-3' style={{ fontFamily: 'Roboto' }} />
                }
              </Tabs>

              <TabPanel index={TabsEditPersonalData.SUBSCRIPTIONS} value={this.state.tab}
                style={{ paddingTop: '28px'}}
              >
                <OrdersWrapper>
                  {this.lists.map(item => (
                    <>
                      {[ListType.ACTIVE, ListType.FINISHED].includes(item.type) && <Divider />}
                      <OrdersHeader>{item.title}</OrdersHeader>
                      <OrdersList
                        props={{
                          old: item.old,
                          type: item.type,
                          state: item.state,
                          history: this.props.history,
                          orderOpenModal: this.state.orderOpenModal,
                          currentUser: this.state.currentUser,
                          clearQuery: () => this.clearQuery(),
                        }}
                      />
                    </>
                  ))}
                </OrdersWrapper>
              </TabPanel>
              <TabPanel
                index={TabsEditPersonalData.PERSONAL_DATA}
                value={this.state.tab}
                style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
              >
                <ProfileAccount
                />
              </TabPanel>
              <TabPanel
                index={TabsEditPersonalData.ADDRESS_DATA}
                value={this.state.tab}
                style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
              >
                <ProfileAddresses />
              </TabPanel>
              <TabPanel
                index={TabsEditPersonalData.CARDS_DATA}
                value={this.state.tab}
                style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
              >
                <ProfileCards />
              </TabPanel>
            </Stack>
          </Container>
        </MainLayout>
      </>
    );
  }
}

export default withRouter(connect((state: IAppState) => ({
  orderConfirmation: state.general.orderConfirmed,
  loggedState: state.user.logged,
  hasNormalOrder: state.user.hasNormalOrder
}), (dispatch: Dispatch<AnyAction>) => ({
  logUser: (token: string) => dispatch(LOG_USER(token)),
  setOrderConfirmation: (orderConfirmation: boolean) => dispatch(SET_ORDER_CONFIRMATION(orderConfirmation)),
}))(withApollo(Orders)));
