import * as React from "react";
import { RouteComponentProps } from "react-router";
import MainLayout from "../../../../layouts/Main";
import { CardInfo, Container } from "../styled";
import { Query, QueryResult } from "react-apollo";
import {
  IQueryOrderByShortId,
  QUERY_ORDER_BY_SHORTID
} from "../../../../graphql/queries/checkoutOrders";
import QueryRenderer from "../../../../components/QueryRenderer";
import { Content, GoBackButton } from './styled';
import Loading from './loading';
import { Info } from './info';

type IProps = {
  shortId: string;
  subscriptionId: string;
};

export class OrderCharges extends React.Component<RouteComponentProps<IProps>> {

  public render() {
    const { shortId } = this.props.match.params;
    
    return (
      <MainLayout hideSearch={true} internal={true}>
        <Container>
          <Content>
            <GoBackButton onClick={() => window.location.href = "/pedidos"}>Voltar</GoBackButton>
            <Query
              query={QUERY_ORDER_BY_SHORTID}
              variables={{ shortId }}
              fetchPolicy="no-cache"
            >
              {(result: QueryResult<IQueryOrderByShortId>) => (
                <QueryRenderer
                  loading={<Loading />}
                  error={
                    <CardInfo>Erro! Contate nosso suporte pelo site!</CardInfo>
                  }
                  result={result}
                  render={({
                    data,
                    loading
                  }: QueryResult<IQueryOrderByShortId>) =>
                    !loading &&
                    data.searchOrderById && (
                      <Info order={data.searchOrderById} />
                    )
                  }
                />
              )}
            </Query>
          </Content>
        </Container>
      </MainLayout>
    );
  }
}

export default OrderCharges;
