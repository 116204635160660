import * as React from "react";
import { IPropsWithMode } from './info';
import { Divider, Grid } from '@mui/material';
import { uppercaseFirstLetter } from '../../../../helpers/uppercasefirstLetter';
import toReal from '../../../../helpers/toReal';
import { ActionButton, OrderInfo, Title, Info } from './styled';
import { State } from '../../../../graphql/types/checkoutOrders';

export const Order = (props: IPropsWithMode) => {
  const { order } = props;
  const subscription = order.subscription

  const card = () => {
    const charges = order.charges?.filter(charge => charge.transactions.length > 0)
    if (charges.length === 0) return

    const lastCharge = charges[charges.length - 1]
    if (!lastCharge) return

    const transaction = lastCharge?.transactions[lastCharge.transactions.length - 1]
    
    const brand = transaction?.cardBrand || '-'
    const lastDigits = transaction?.cardLastDigits || '-'
    if (transaction) {
      return <Info small={true}>{uppercaseFirstLetter(brand)} final <strong>{lastDigits}</strong></Info>
    }
  }

  const handleContractualTerm = () => {
    window.open(props.order.contract, '_blank')
  } 

  const showContract = 
    props.order.contract &&
    props.order.checkoutOrderProducts.some(cop => [
      State.pedidoEntregue,
      State.devolucaoAtrasada,
      State.produtoDevolvido,
      State.produtoVendido,
      State.devolucaoPendente,
      State.assinaturaRenovada,
    ].includes(cop.state as State))
  
  return (
    <>
      <Title>Pedido: {order.shortId}</Title>
      {order.checkoutOrderProducts
        ?.filter(({ state }) => state !== State.recuperado)
        ?.map(cop => (
        <OrderInfo key={cop._id}>
          <Grid container flexDirection={'row'} spacing={1} justifyContent={'space-between'}>
            <Grid xs={0} md><img src={cop.product.photos[0]} /></Grid>
            <Grid item xs={12} md={3}>
              <Info>{cop.product.name}</Info><br />
              <Info small>{cop.product.technicalDetails}</Info>
            </Grid>
              <Grid item xs={12} md={3}>
                <Info><strong>Plano de fidelidade: </strong>{subscription?.cycleQuantity} meses</Info>
              </Grid>
              <Grid item xs={12} md={3}>
                <Info><strong>Valor: </strong>{toReal(subscription?.cycleValue/100)}/mês</Info>
                <br />
                {card()}
              </Grid>
              {showContract && <Grid item xs={12} md={2} container justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
                <ActionButton style={{ fontFamily: 'Lato' }} onClick={handleContractualTerm}>TERMO CONTRATUAL</ActionButton>
              </Grid>}
          </Grid>
          <Divider style={{ marginBlock: '20px' }} />
        </OrderInfo>
      ))}
    </>
  )
}