import * as React from "react";
import { IQueryOrderByShortId } from '../../../../graphql/queries/checkoutOrders';
import { Order } from './info.order';
import { Charges } from './info.charges';
import { sizes } from '../../../../helpers/style';

type IProps = {
  order: IQueryOrderByShortId['searchOrderById']
}

export type IPropsWithMode = IProps & {
  isMobile: boolean
}

export const Info = (props: IProps) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(() => {
    if (window.screen.width <= sizes.transitional || window.innerWidth <= sizes.transitional) {
      return true;
    } else {
      return false;
    }
  });
  
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      function() {
        if (window.innerWidth <= sizes.transitional) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      },
      { passive: true }
    );
  }, []);

  return (
    <>
      <Order order={props.order} isMobile={isMobile} />
      <Charges order={props.order} isMobile={isMobile} />
    </>
  )
}