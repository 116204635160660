import React from "react";
import { Divider, Grid, Skeleton } from '@mui/material';
import { Content } from './styled';
import { sizes } from '../../../../helpers/style';

const Loading = () => {
  const [isMobile, setIsMobile] = React.useState<boolean>(() => {
    if (window.screen.width <= sizes.transitional || window.innerWidth <= sizes.transitional) {
      return true;
    } else {
      return false;
    }
  });
  
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      function() {
        if (window.innerWidth <= sizes.transitional) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      },
      { passive: true }
    );
  }, []);

  if (isMobile) return Mobile()
  return Web()
};

const Web = () => {
  return (
    <Content style={{ marginTop: '20px', marginBottom: '20px', minHeight: '100vh', alignContent: 'flex-start' }}>
      
      <Skeleton variant='rounded' width={250} height={25} />
      <Grid container style={{ marginTop: '12px' }}>
        <Grid item xs={12} md={2}><Skeleton variant='rounded' width={'80%'} height={80} style={{ marginRight: 'auto' }} /></Grid>
        <Grid item xs={12} md={2}><Skeleton variant='text' width={'80%'} height={40} style={{ marginRight: 'auto' }} /></Grid>
        <Grid item xs={12} md={2}><Skeleton variant='text' width={'80%'} height={40} style={{ marginRight: 'auto' }} /></Grid>
        <Grid item xs={12} md={2}><Skeleton variant='text' width={'80%'} height={40} style={{ marginRight: 'auto' }} /></Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={2}><Skeleton variant='rounded' width={'80%'} height={20} /></Grid>
      </Grid>

      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

      <Skeleton variant='rounded' width={280} height={25} style={{ marginBottom: '10px' }} />
      <Skeleton variant='rounded' width={'80%'} height={20} />
      {Array(2).fill(0).map((_, index) => (
        <Grid key={index} container style={{ marginTop: '12px' }}>
          <Grid item xs={12} md={3}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={12} md={2}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={12} md={2}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={0} md={2}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={0} md={3}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
        </Grid>
      ))}

      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

      <Skeleton variant='rounded' width={180} height={25} style={{ marginBottom: '10px' }} />
      {Array(10).fill(0).map((_, index) => (
        <Grid key={index} container style={{ marginTop: '12px' }}>
          <Grid item xs={12} md={3}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={12} md={2}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={12} md={2}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={0} md={2}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
          <Grid item xs={0} md={3}><Skeleton variant='text' width={"70%"} height={40} style={{ marginRight: 'auto' }} /></Grid>
        </Grid>
      ))}

    </Content>
  );
}

const Mobile = () => {
  return (
    <Content style={{ marginTop: '20px', marginBottom: '20px', minHeight: '100vh', alignContent: 'flex-start' }}>
      
      <Skeleton variant='rounded' width={'60%'} height={40} style={{ marginBottom: '30px' }} />
      <Skeleton variant='rounded' width={'80%'} height={30} style={{ marginBottom: '10px' }} />
      <Skeleton variant='rounded' width={'40%'} height={20} style={{ marginBottom: '30px' }} />
      <Skeleton variant='rounded' width={'77%'} height={30} style={{ marginBottom: '30px' }} />
      <Skeleton variant='rounded' width={'37%'} height={30} style={{ marginBottom: '10px' }} />
      <Skeleton variant='rounded' width={'57%'} height={20} style={{ marginBottom: '30px' }} />
      <Skeleton variant='rounded' width={'30%'} height={40} />

      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

      <Skeleton variant='rounded' width={'60%'} height={40} style={{ marginBottom: '20px' }} />
      <Skeleton variant='rounded' width={'90%'} height={30} style={{ marginBottom: '10px' }} />
      <Skeleton variant='rounded' width={'95%'} height={30} style={{ marginBottom: '10px' }} />
      <Skeleton variant='rounded' width={'80%'} height={30} style={{ marginBottom: '30px' }} />
      {Array(2).fill(0).map((_, index) => <Skeleton key={index} variant='rounded' width={'100%'} height={80} style={{ marginBottom: '10px' }} />)}

      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

      <Skeleton variant='rounded' width={'60%'} height={30} style={{ marginBottom: '20px' }} />
      {Array(10).fill(0).map((_, index) => <Skeleton key={index} variant='rounded' width={'100%'} height={80} style={{ marginBottom: '10px' }} />)}

    </Content>
  );
}

export default Loading;
