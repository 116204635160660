import { History } from 'history';
import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import QueryRenderer from '../../../components/QueryRenderer';
import { IQueryMyOrders, QUERY_MY_ORDERS } from '../../../graphql/queries/checkoutOrders';
import OrderProducts, { ListType } from './OrderProducts';
import * as moment from 'moment-timezone';
import { CheckoutOrderStatus } from '../../../graphql/types/checkoutOrders';
import { PaymentType } from '../../../actionReducers/Checkout';
import { Skeleton } from '@mui/material';
import { CardInfo } from './styled';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from "react-redux";
import { IUserAction, SET_HAS_NORMAL_ORDER } from '../../../actionReducers/User';
import { ActionCreator, Dispatch } from 'redux';
import { IPublicUser } from '../../../graphql/types/users';

interface IProps {
  props: IOrdersList;
  setHasNormalOrder: ActionCreator<IUserAction>;
}

type IOrdersList = {
  history?: History;
  type: ListType;
  old?: Boolean;
  state?: String;
  currentUser: IPublicUser;
  orderOpenModal?: String;
  clearQuery(): void;
}

const OrdersList = ({ props, setHasNormalOrder }: IProps & RouteComponentProps) => {
  const { old, state, history } = props
  const SECONDS_VALIDATE_PIX = 24 * 60 * 60;
  let variables: Object = {}

  if (old !== undefined) variables['old'] = old
  if (state !== undefined) variables['state'] = state

  variables['page'] = 1
  variables['limit'] = 999

  const isValidQRCodePix = (dateOrder: string) => {
    const now = moment.tz(moment(), 'America/Sao_Paulo');
    const orderDateTimeZoneSP = moment.tz(dateOrder, 'America/Sao_Paulo');
    const diff = now.diff(orderDateTimeZoneSP, 'seconds');
    return diff < SECONDS_VALIDATE_PIX;
  }

  const isToValidatePicpay = (order) => {
    return order.dataPayment?.payment_method === PaymentType.PICPAY && order.status === CheckoutOrderStatus.GENERATED_LINK
  }

  return (
    <Query query={QUERY_MY_ORDERS} variables={variables} fetchPolicy='no-cache'>
      {
        (result: QueryResult<IQueryMyOrders>) => (
          <QueryRenderer
            loading={(
              <>
                <Skeleton animation='wave' variant='rounded' width={'100%'} height={100} style={{ marginBottom: '20px' }} />
                <Skeleton animation='wave' variant='rounded' width={'100%'} height={100} style={{ marginBottom: '20px' }} />
                <Skeleton animation='wave' variant='rounded' width={'100%'} height={100} style={{ marginBottom: '20px' }} />
              </>
            )}
            error={<CardInfo>Erro! Contate nosso suporte pelo site!</CardInfo>}
            result={result}
            render={({ data, loading }: QueryResult<IQueryMyOrders>) =>
              !loading && data.myOrders && data.myOrders.map(
                (order) => {
                  let orderEnableToShow = false;
                  if (order.status === CheckoutOrderStatus.GENERATED_PIX || isToValidatePicpay(order)) {
                    orderEnableToShow = isValidQRCodePix(order.createdAt);
                  } else {
                    orderEnableToShow = true;
                  }
                  if (!orderEnableToShow) return null;
                  if(!order.recurrence) setHasNormalOrder(true);
                  return (
                    <OrderProducts
                      next={true}
                      refetchOrders={result.refetch}
                      key={order._id}
                      order={order}
                      type={props.type}
                      history={history}
                      orderOpenModal={props.orderOpenModal}
                      clearQuery={props.clearQuery}
                      currentUser={props.currentUser}
                    />
                  )
                },
              )
            }
          />
        )
      }
    </Query>
  );
}


export default withRouter(
  connect(null, (dispatch: Dispatch<IUserAction>) => ({
    setHasNormalOrder: (value: boolean) =>
      dispatch((SET_HAS_NORMAL_ORDER(value)))
  }))(OrdersList)
);